import { type ActiveSgProgrammeType } from '@activesg/constants'

export const SIGN_IN = '/sign-in'
export const SIGN_IN_SELECT_PROFILE_SUBROUTE = '/select-profile'

export const REPORTS_ROOT = '/reports'
export const FACILITIES_ROOT = '/facilities'
export const HOME = '/'

export const OVERVIEW_ROUTES = {
  root: () => '/overview',
}

export const BOOKING_ROUTES = {
  root: () => '/bookings',
  add: () => ({
    root: () => `${BOOKING_ROUTES.root()}/add`,
    individual: () => `${BOOKING_ROUTES.add().root()}/individual`,
    corporate: () => `${BOOKING_ROUTES.add().root()}/corporate`,
  }),
}

export const PASSES_ROUTES = {
  root: () => '/passes',
  id: (id: string) => `${PASSES_ROUTES.root()}/${id}`,
}

export const PAYMENT_LINK_ROUTES = {
  root: () => `/payment-link`,
  id: (id: string) => `${PAYMENT_LINK_ROUTES.root()}/${id}`,
}

export const CONSUMPTION_ROUTES = {
  root: () => '/consumption',
}

export const PRINCIPAL_ROUTES = {
  root: () => '/principals',
  upsert: () => `${PRINCIPAL_ROUTES.root()}/upsert`,
}

export const MEMBER_ROUTES = {
  root: () => '/members',
  individuals: () => ({
    root: () => `${MEMBER_ROUTES.root()}/individuals`,
    add: () => `${MEMBER_ROUTES.individuals().root()}/add`,
    member: (id: string) => ({
      root: () => `${MEMBER_ROUTES.individuals().root()}/${id}`,
    }),
  }),
  memberships: () => ({
    root: () => `${MEMBER_ROUTES.root()}/memberships`,
    membership: (id: string) => ({
      root: () => `${MEMBER_ROUTES.memberships().root()}/${id}`,
      edit: () => MEMBER_ROUTES.memberships().membership(id).root(),
    }),
  }),
}

export const PROGRAMMES_ROUTES = {
  root: () => '/programmes',
  // TODO: When we support multi-tenancy think about changing this to string, then gracefully handling ProgrammeTypes that are not in our DB
  add: (programmeType: ActiveSgProgrammeType) =>
    `${PROGRAMMES_ROUTES.root()}/add/${programmeType}`,
  view: () => ({
    root: () => `${PROGRAMMES_ROUTES.root()}/view`,
    id: (id: string) => `${PROGRAMMES_ROUTES.view().root()}/${id}`,
  }),
} as const

export const CORPORATIONS_ROUTES = {
  root: () => '/corporations',
  add: () => `${CORPORATIONS_ROUTES.root()}/add`,
  corporation: (id: string) => ({
    root: () => `${CORPORATIONS_ROUTES.root()}/${id}`,
    edit: () => `${CORPORATIONS_ROUTES.corporation(id).root()}/edit`,
  }),
}

export const VENUES = `${FACILITIES_ROOT}/venues`
